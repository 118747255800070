import React from "react";
import tw from "twin.macro";
import { H2, H5, P } from "../../utils/helpers.js";
import Covid from "../../img/covid.svg";
import Car from "../../img/car.svg";
import Uber from "../../img/uber.svg";
import Lyft from "../../img/lyft.svg";
import Window from "../../img/window.svg";
import Seatbelt from "../../img/seatbelt.svg";
import DoorHandle from "../../img/door-handle.svg";

const CovidWrapper = tw.section`
  px-4 lg:px-8 max-w-7xl mx-auto bg-ink text-white text-center px-4 py-12 pb-20 md:py-20
`;

const TitleRow = tw.div`
  flex flex-col md:flex-row justify-center items-center
`;

const Title = tw(H2)`
  mx-0 my-4
`;

const BodyCopy = tw(P)`
  max-w-3xl text-lg mx-auto my-6
`;

const GermInfo = tw.div`
  flex flex-col md:flex-row w-full my-8 max-w-5xl mx-auto
`;

const GermBlock = tw.div`
  max-w-lg mx-auto md:w-1/2 flex-none text-left my-6
`;

const CovidInfo = () => (
  <CovidWrapper className="gradient-bg">
    <TitleRow>
      <Covid tw="hidden md:block" />
      <Title>Jeevz helps reduce your exposure to COVID when you go out.</Title>
      <Covid />
    </TitleRow>
    <BodyCopy>
      The average rideshare vehicle has 6 million germs and the average rental
      car has 3 million. Minimize your risk by using Jeevz and your own car.
    </BodyCopy>
    <GermInfo>
      <GermBlock>
        <H5>Average germs found in alternatives:</H5>
        <div tw="flex flex-col items-start md:flex-row py-6 md:pr-6 md:items-center">
          <div tw="md:w-28 mb-4">
            <div tw="flex flex-row items-end mb-2">
              <Uber tw="w-12 h-8 mr-3" />
              <Lyft tw="w-12 h-6" />
            </div>
            <span>Ride shares</span>
          </div>
          <div tw="h-12 bg-linen w-full md:w-72 uppercase tracking-wide text-lg flex flex-row items-center p-4 md:ml-6">
            6 million
          </div>
        </div>
        <div tw="flex flex-col items-start md:flex-row py-6 md:pr-6 md:items-center">
          <div tw="md:w-28 mb-4">
            <div tw="flex flex-row items-end mb-2">
              <Car tw="w-8 h-8 mr-3" />
              <Car tw="w-8 h-8" />
            </div>
            <span>Rental cars</span>
          </div>
          <div tw="h-12 bg-linen w-1/2 md:w-36 uppercase tracking-wide text-lg flex flex-row items-center p-4 md:ml-6">
            3 million
          </div>
        </div>
      </GermBlock>
      <GermBlock tw="md:pl-12 md:border-l">
        <H5>Most common germ* deposits in rideshares:</H5>
        <div tw="grid grid-cols-3 gap-6">
          <div>
            <Window tw="w-16 h-16 my-3" />
            <p tw="font-bold">5 million</p>
            <p>found on window buttons</p>
          </div>
          <div>
            <Seatbelt tw="w-24 h-16 my-3" />
            <p tw="font-bold">1 million</p>
            <p>found on seatbelts</p>
          </div>
          <div>
            <DoorHandle tw="w-16 h-16 my-3" />
            <p tw="font-bold">1,810</p>
            <p>found on door handles</p>
          </div>
        </div>
        <p tw="text-xs text-linen mt-6">
          * Measured in colony-forming units (CFUs) per square inch; Source -
          Netquote study Geourge Petras / USA today
        </p>
      </GermBlock>
    </GermInfo>
  </CovidWrapper>
);

export default CovidInfo;
